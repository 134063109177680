<template>
    <div>
        <h3>Currency</h3>

        <v-btn
            text
            :to="{ name: 'manage-websites', params: { domainname: siteUrl }}"
            outlined
            class="btn btn-lg btn-secondary-main"
            style="background:transparent;"
        >
            <i class="fas fa-arrow-left"></i> Back
        </v-btn>
        <span class="btn btn-label-primary btn-sm btn-bold kt-margin-r-10 pull-right">
      <i class="fa fa-globe"></i>
      {{siteUrl}}
    </span>
        <currency-setting
            :page_setting="pageSetting"
            :loading="loading"
            @update_currency="updateWebsiteSetting"
        ></currency-setting>
    </div>
</template>
<script>

    import CurrencySetting from "./CurrencySetting";
    import WebsiteSetting from "@/services/Websites/WebsiteSettingService";

    const WebsiteSettingService = new WebsiteSetting();

    export default {
        name: "curr",
        data() {
            return {
                pageSetting: null,
                loading: false,
            };
        },
        components: {
            CurrencySetting,
        },
        mounted() {
            this.getSettings()
        },
        methods: {
            getSettings() {
                WebsiteSettingService.paginate(this.siteUrl).then(res => {
                    this.pageSetting = res.data;
                    this.loading = false;
                });
            },
            updateWebsiteSetting(data) {
                this.loading = true;

                this.$confirm(
                    {
                        message: `Are you sure?`,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                WebsiteSettingService.update(
                                    this.siteUrl,
                                    this.pageSetting.id,
                                    data
                                ).then(res => {
                                    this.$snotify.success("Settings  Updated");
                                    this.getSettings();
                                });
                            }
                        }
                    }
                )

            }
        },
        computed: {

            siteUrl() {
                return this.$route.params.domainname;
            },

        },
    };
</script>
