import Apiservice from '../../common/api.service';
import {API_URL} from '../../common/config.js'

const apiService = Apiservice;


export default class WebsiteSettingService {

    enableDisablePage(websiteSlug, pageTitle) {
        // http://yelkov.stage/user/website/aarrunyal.yelkov.stage/page/Blog/enable-disable
        let url = API_URL + 'user/website/' + websiteSlug + '/page/' + pageTitle + '/enable-disable';
        return apiService.get(url);

    }


    paginate(slug, index = null) {
        if (slug != undefined) {
            let url = API_URL + 'user/website/' + slug + '/setting';
            if (index != null)
                url = url + '?page=' + index
            return apiService.get(url);
        }
    }

    _get(slug) {
        let url = API_URL + 'user/website/' + slug + '/setting';
        return apiService.get(url);
    }


    create(slug, data) {
        let url = API_URL + 'user/website/' + slug + '/setting'
        return apiService.post(url, data);
    }

    update(slug, id, data) {
        let url = API_URL + 'user/website/' + slug + '/setting/' + id + "/update"
        return apiService.post(url, data);
    }

    updateLayout(slug, id, data) {
        let url = API_URL + 'user/website/' + slug + '/layout/setting/' + id + "/update"
        return apiService.post(url, data);
    }

    delete(slug, id) {
        let url = API_URL + 'user/website/' + slug + '/setting/' + id
        return apiService.delete(url);
    }

    show(slug, id) {
        let url = API_URL + 'user/website/' + slug + '/setting/' + id
        return apiService.get(url);
    }

    settingByWebsiteId(websiteId) {
        let url = API_URL + `user/website/${websiteId}/website-setting`;
        return apiService.get(url);
    }

    isStore(websiteDomain) {
        let url = API_URL + `user/website/${websiteDomain}/is-store`;
        return apiService.get(url);
    }


    removeImage(websiteId, image) {
        let url = API_URL + `user/website/${websiteId}/remove-image/${image}`;
        return apiService.get(url);
    }

    publishSetting(domainName) {
        let url = API_URL + `user/website/${domainName}/website-setting/publish`;
        return apiService.get(url);
    }

    restoreSetting(domainName) {
        let url = API_URL + `user/website/${domainName}/website-setting/restore`;
        return apiService.get(url);
    }  enablePreviewMode(domainName) {
        let url = API_URL + `user/website/${domainName}/website-setting/enable-preview`;
        return apiService.get(url);
    }



}
