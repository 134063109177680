<template>
  <v-app>
    <div class="row" v-if="loadingPage">
      <div class="col-md-12 mt-2">
        <v-skeleton-loader class="mx-auto" type="list-item-three-line"></v-skeleton-loader>
      </div>
    </div>
    <v-card flat class="mt-2" v-else-if="!loading">
      <v-card-title>Setup your store to accept multiple currencies</v-card-title>
      <v-card-text>
        <p>With Multi-currency, you can setup your store to sell products in different currencies. Based on currency selected, different payment methods are available. If no payment method is available then only Cash on Delivery option will be available.</p>

        <br />
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="defaultCurrency"
              outlined
              densed
              :items="countries"
              :item-text="item => `${item.title} - ${item.currency} (${item.symbol}) `"
              item-value="id"
              label="Default Store Currency"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-switch v-model="setting.enable_currency" label="Enable Multicurrency Store"></v-switch>
          </v-col>
          <v-col cols="12" v-if="setting.enable_currency">
            <v-select
              v-model="availableOption"
              outlined
              densed
              chips
              multiple
              :items="countries"
              :item-text="item => `${item.title} [${item.currency} (${item.symbol})]`"
              item-value="id"
              label="Available Currencies"
            ></v-select>
          </v-col>

        </v-row>

        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn :loading="loading" color="primary" @click="updateCurrency">Update</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- <pre>{{availableOption}}</pre> -->
  </v-app>
</template>
<script>
import EcommerceCurrencyPricingService from "@/services/store/EcommerceCurrencyPricingService";
import WebsiteSetting from "@/services/Websites/WebsiteSettingService";
import Country from "@/services/address/CountryService";

const settingService = new WebsiteSetting();
const currencyPricing = new EcommerceCurrencyPricingService();
const country = new Country();

export default {
  name: "currency-setting",
  props: ["loading"],
  data() {
    return {
      setting: {},
      countries: [],
      currencies: [],
      defaultCurrency: null,
      availableOption: [],
      enableCurrency: false,
      domainName: null,
      loadingPage:true
    };
  },
  mounted() {
    this.domainName = this.$route.params.domainname;
    this.websiteSetting();
    this.getCurrencies();
  },
  methods: {
    websiteSetting() {
      settingService.paginate(this.domainName).then(res => {
        this.setting = res.data;
        if (this.setting)
          this.defaultCurrency = Number(this.setting.default_currency);

        // console.log(this.defaultCurrency)
        this.availableOption = this.setting.available_currencies.map(item => {
          return Number(item);
        });
      }).finally(() => (this.loadingPage = false));;
    },
    updateCurrency() {
      let data = {
        enable_currency: this.setting.enable_currency,
        default_currency: this.defaultCurrency,
        available_currencies: this.availableOption
      };
      this.$emit("update_currency", data);
    },
    getCurrencies() {
      country
        .all()
        .then(response => {
          this.countries = response.data;
        })
        .catch(error => {
          // console.log(error);
        });
    }
  }
};
</script>
